import React from 'react';
import Particles from 'react-particles-js';

import logo from './assets/logo.png';
import github from './assets/github.png';
import linkedin from './assets/linkedin.png';
import twitter from './assets/twitter.png';

const particleParams = {
  particles: {
    number: {
      value: 200,
      density: {
        enable: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        speed: 4,
        size_min: 0.3,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      random: true,
      speed: 1,
      direction: 'top',
      out_mode: 'out',
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'bubble',
      },
    },
    modes: {
      bubble: {
        distance: 250,
        duration: 2,
        size: 0,
        opacity: 0,
      },
      repulse: {
        distance: 200,
        duration: 4,
      },
    },
  },
};

const image = {
  height: '50px',
  width: '50px',
  padding: '20px',
  zIndex: 999,
};

function App() {
  return (
    <div
      style={{
        background: '#222',
        minHeight: '100vh',
        width: '100%',
        fontFamily: 'Meslo',
      }}
    >
      <Particles
        style={{ height: '100%', width: '100%', position: 'absolute' }}
        params={particleParams as any}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: '100vh',
          pointerEvents: 'none',
          zIndex: 99,
        }}
      >
        <img
          style={{ height: '200px', width: '200px', zIndex: 999 }}
          src={logo}
          alt="logo"
        />
        <h1 style={{ color: 'white' }}>Griffin Martin</h1>
        <div
          style={{
            flexWrap: 'wrap',
            flexDirection: 'row',
            pointerEvents: 'all',
            zIndex: 999,
          }}
        >
          <a
            href="https://www.linkedin.com/in/griffinmartin94/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img style={image as any} src={linkedin} alt="linkedin" />
          </a>
          <a
            href="https://github.com/griffinmartin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img style={image as any} src={github} alt="github" />
          </a>
          <a
            href="https://twitter.com/_griffinmartin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img style={image as any} src={twitter} alt="twitter" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
